var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import { Viewer } from "../Viewer";
import { load, reducer } from "../Viewer/duck";
import { usePromise } from "@cadbox1/use-promise";
import { ThemeProvider, Container, Styled } from "theme-ui";
import theme from "./theme";
var store = configureStore({
    reducer: reducer,
});
var WebViewerInside = function (_a) {
    var url = _a.url, showBanner = _a.showBanner;
    var dispatch = useDispatch();
    var request = usePromise({
        promiseFunction: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!url) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, axios.get(url)];
                    case 1:
                        response = _a.sent();
                        dispatch(load({ data: response.data }));
                        return [2 /*return*/];
                }
            });
        }); },
    });
    useEffect(function () {
        request.call();
    }, [url]);
    // sneakily load the source sans pro font
    useEffect(function () {
        var link = document.createElement("link");
        link.setAttribute("href", "https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
        link.setAttribute("rel", "stylesheet");
        document.body.appendChild(link);
        return function () {
            document.body.removeChild(link);
        };
    }, []);
    return (jsx("div", null,
        showBanner && (jsx("div", { sx: { fontSize: 1, marginBottom: 4 } },
            "Made with",
            " ",
            jsx(Styled.a, { href: "https://verynested.cadell.dev", target: "_blank", rel: "noopener noreferrer" }, "Very Nested"))),
        request.pending ? ("Loading...") : request.rejected ? ("There was an error loading this page. Please refresh to try again.") : (jsx(Viewer, { readonly: true }))));
};
export var WebViewer = function (_a) {
    var _b = _a.url, url = _b === void 0 ? "./very-nested-data.json" : _b, _c = _a.showBanner, showBanner = _c === void 0 ? true : _c;
    return (jsx(Provider, { store: store },
        jsx(ThemeProvider, { theme: theme },
            jsx(Container, null,
                jsx(WebViewerInside, { url: url, showBanner: showBanner })))));
};
