var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HotKeys } from "react-hotkeys";
import { IoIosArrowRoundBack, IoIosArrowRoundForward, IoIosArrowRoundUp, IoIosArrowRoundDown, IoIosArrowUp, IoIosArrowDown, IoIosTrash, IoIosCloudUpload, } from "react-icons/io";
import { ItemNode } from "./ItemNode";
import { up, down, indentItem, undentItem, addItem, removeItem, moveUp, moveDown, editItem, setBaseUrl, ROOT_ID, getPathFromNodeId, } from "./duck";
import { ToolbarButton } from "./ToolbarButton";
import { getLastItemInArray, objectMap } from "./array-util";
import { FixedToolbar } from "./FixedToolbar";
import { ToolbarUploadButton } from "./ToolbarUploadButton";
export var Viewer = function (_a) {
    var _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.baseUrl, baseUrl = _c === void 0 ? "" : _c, onUpload = _a.onUpload;
    var dispatch = useDispatch();
    var selectedNodeId = useSelector(function (state) { return state.nodeId; });
    var id = getLastItemInArray(getPathFromNodeId(selectedNodeId));
    var selectedItem = useSelector(function (state) { return state.item[id]; });
    useEffect(function () {
        dispatch(setBaseUrl({ baseUrl: baseUrl }));
    }, [dispatch, baseUrl]);
    var actions = {
        up: up,
        down: down,
        indent: indentItem,
        undent: undentItem,
        moveUp: moveUp,
        moveDown: moveDown,
        enter: function () { return addItem(); },
    };
    var preparedHandlers = objectMap(actions, function (_a) {
        var handler = _a.value;
        return function (evt) {
            if (evt) {
                evt.preventDefault();
            }
            dispatch(handler());
        };
    });
    var handleRemove = function () {
        dispatch(removeItem());
    };
    var handlers = __assign(__assign({}, preparedHandlers), { backspace: function (evt) {
            if (selectedItem.content === "") {
                if (evt) {
                    evt.preventDefault();
                }
                handleRemove();
            }
        } });
    var handleUploadComplete = function (uri) {
        dispatch(editItem({ id: id, content: uri }));
    };
    return (jsx("div", null,
        jsx(HotKeys, { tabIndex: undefined, keyMap: {
                up: "up",
                down: "down",
                indent: "tab",
                undent: "shift+tab",
                moveUp: "alt+up",
                moveDown: "alt+down",
                enter: "enter",
                backspace: "backspace",
            } },
            jsx(HotKeys, { tabIndex: undefined, handlers: handlers },
                jsx("div", { style: {
                        width: "100%",
                        maxWidth: "800px",
                        overflow: "auto",
                    } },
                    jsx("ul", { style: {
                            paddingLeft: 0,
                            paddingBottom: "3rem",
                            margin: 0,
                        } },
                        jsx(ItemNode, { nodeId: ROOT_ID, readonly: readonly }))),
                selectedItem && (jsx(FixedToolbar, null,
                    jsx("div", { sx: {
                            display: "inline-block",
                            backgroundColor: "background",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "muted",
                        } },
                        jsx(ToolbarButton, { onClick: function () {
                                preparedHandlers.undent();
                            }, title: "undent (shift + tab)" },
                            jsx(IoIosArrowRoundBack, null)),
                        jsx(ToolbarButton, { onClick: function () {
                                preparedHandlers.indent();
                            }, title: "indent (tab)" },
                            jsx(IoIosArrowRoundForward, null)),
                        jsx(ToolbarButton, { onClick: function () {
                                preparedHandlers.moveUp();
                            }, title: "move up (alt + up)" },
                            jsx(IoIosArrowRoundUp, null)),
                        jsx(ToolbarButton, { onClick: function () {
                                preparedHandlers.moveDown();
                            }, title: "move down (alt + down)" },
                            jsx(IoIosArrowRoundDown, null)),
                        jsx(ToolbarButton, { onClick: function () {
                                preparedHandlers.up();
                            }, title: "previous item (up)" },
                            jsx(IoIosArrowUp, null)),
                        jsx(ToolbarButton, { onClick: function () {
                                preparedHandlers.down();
                            }, title: "next item (down)" },
                            jsx(IoIosArrowDown, null)),
                        jsx(ToolbarButton, { onClick: handleRemove, title: "remove item" },
                            jsx(IoIosTrash, null)),
                        onUpload && (jsx(ToolbarUploadButton, { onUpload: onUpload, onUploadComplete: handleUploadComplete, title: "upload item" },
                            jsx(IoIosCloudUpload, null))))))))));
};
