import React, { useState, useEffect } from "react";
import { Fragment } from "react";
export var FixedToolbar = function (_a) {
    var children = _a.children;
    var _b = useState(0), topOffset = _b[0], setTopOffset = _b[1];
    var handleViewport = function () {
        var layoutViewport = document.getElementById("layoutViewport");
        if (!layoutViewport) {
            return;
        }
        // @ts-ignore
        var viewport = window.visualViewport;
        var offsetTop = viewport.height -
            layoutViewport.getBoundingClientRect().height +
            viewport.offsetTop;
        setTopOffset(offsetTop);
    };
    useEffect(function () {
        // @ts-ignore
        if (window.visualViewport !== undefined) {
            // @ts-ignore
            window.visualViewport.addEventListener("scroll", handleViewport);
            // @ts-ignore
            window.visualViewport.addEventListener("resize", handleViewport);
            return function () {
                // @ts-ignore
                window.visualViewport.removeEventListener("scroll", handleViewport);
                // @ts-ignore
                window.visualViewport.removeEventListener("resize", handleViewport);
            };
        }
    }, []);
    return (React.createElement(Fragment, null,
        React.createElement("div", { id: "layoutViewport", style: { position: "fixed", height: "100%", width: "100%" } }),
        React.createElement("div", { style: {
                position: "fixed",
                bottom: 0,
                width: "100%",
                transform: "translate(0, " + topOffset + "px)",
            } }, children)));
};
