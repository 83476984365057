var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronForward, IoChevronDown } from "react-icons/io5";
import { editItem, selectItem, collapse, expand, getPathFromNodeId, getNodeIdFromPath, ROOT_ID, } from "./duck";
import { getLastItemInArray } from "./array-util";
import { isHref, possiblyPrependBaseUrl, isImageSrc } from "./isHref";
import { TimelineNode } from "./TimelineNode";
import { ItemNode } from "./ItemNode";
export var Node = function (_a) {
    var nodeId = _a.nodeId, content = _a.content, children = _a.children, expandedProp = _a.expanded, readonly = _a.readonly;
    var path = getPathFromNodeId(nodeId);
    var itemId = getLastItemInArray(path);
    var isRoot = itemId === ROOT_ID;
    var baseUrl = useSelector(function (state) { return state.baseUrl; });
    var selectedNodeId = useSelector(function (state) { return state.nodeId; });
    var expanded = useSelector(function (state) {
        return state.expanded.includes(nodeId);
    });
    var selected = selectedNodeId === nodeId;
    var dispatch = useDispatch();
    useEffect(function () {
        if (expandedProp) {
            dispatch(expand({ path: path }));
        }
    }, [expandedProp]);
    var handleChange = function (evt) {
        dispatch(editItem({ id: itemId, content: evt.target.value }));
    };
    var handleClick = function () {
        if (readonly) {
            handleExpandCollpase();
        }
        else {
            dispatch(selectItem({ nodeId: nodeId }));
        }
    };
    var handleExpandCollpase = function () {
        if (!children.length && !isImageSrc(content)) {
            return;
        }
        if (expanded) {
            dispatch(collapse({ path: path }));
        }
        else {
            dispatch(expand({ path: path }));
        }
    };
    return (jsx("li", { sx: { listStyleType: "none" } },
        !isRoot && (jsx("div", { sx: {
                fontSize: 1,
                paddingRight: 4,
                variant: "viewer.item",
            } },
            jsx("button", { onClick: handleExpandCollpase, sx: {
                    font: "inherit",
                    width: "26px",
                    padding: 0,
                    border: "none",
                    textAlign: "center",
                    verticalAlign: "top",
                    background: "none",
                    color: "black",
                } }, children.length || isImageSrc(content) ? (expanded ? (jsx(IoChevronDown, { size: 10 })) : (jsx(IoChevronForward, { size: 10 }))) : ("•")),
            jsx("div", { sx: {
                    display: "inline-block",
                    width: "calc(100% - 26px)",
                    position: "relative",
                } },
                jsx("span", { onClick: handleClick, sx: {
                        visibility: selected ? "hidden" : "visible",
                    } },
                    isHref(content) ? (jsx(Styled.a, { href: possiblyPrependBaseUrl(content, baseUrl), target: "_blank", rel: "noopener noreferrer" }, decodeURI(content))) : (content),
                    "\u00A0\u00A0"),
                selected && (jsx("input", { value: content, onChange: handleChange, sx: {
                        font: "inherit",
                        padding: 0,
                        border: "none",
                        outline: "none",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }, autoFocus: true }))))),
        expanded && isImageSrc(content) && (jsx("div", { sx: { paddingLeft: 6 } },
            jsx("img", { src: possiblyPrependBaseUrl(content, baseUrl), sx: {
                    width: "400px",
                    maxWidth: "100vw",
                    my: 4,
                    borderRadius: 4,
                } }))),
        children && expanded && (jsx("ul", { sx: { paddingLeft: isRoot ? 0 : 4, mb: 1 } }, children.map(function (child) {
            return child === "timeline" ? (jsx(TimelineNode, { key: getNodeIdFromPath(__spreadArrays(path, [child])), nodeId: getNodeIdFromPath(__spreadArrays(path, [child])) })) : typeof child === "string" ? (jsx(ItemNode, { key: getNodeIdFromPath(__spreadArrays(path, [child])), nodeId: getNodeIdFromPath(__spreadArrays(path, [child])), readonly: readonly })) : (jsx(Node, { key: child.nodeId, nodeId: child.nodeId, content: child.content, children: child.children, readonly: child.readonly, expanded: child.expanded }));
        })))));
};
