var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import shortid from "shortid";
import { format, parse, isToday, formatDistance, isValid } from "date-fns";
import { createAction, createReducer } from "@reduxjs/toolkit";
import enGB from "date-fns/locale/en-GB";
import emptyState from "./emptyState.json";
import { getItemInArrayByIndex, insertItemInArrayAfter, getIndexFromItem, removeItemFromArray, } from "./array-util";
// I sure do regret not calling this root from the start but I can't be bothered updating the template right now
export var ROOT_ID = "vLlFS3csq";
export var DATE_FORMAT = "yyyy-MM-dd";
export var load = createAction("LOAD");
export var setBaseUrl = createAction("SET_BASE_URL");
export var selectItem = createAction("SELECT_ITEM");
export var editItem = createAction("EDIT_ITEM");
export var addItem = createAction("ADD", function () { return ({
    payload: {
        id: shortid.generate(),
        content: "",
        children: [],
    },
}); });
// Remove Item
export var removeItem = createAction("REMOVE_ITEM");
export var indentItem = createAction("INDENT_ITEM");
export var undentItem = createAction("UNDENT_ITEM");
export var moveUp = createAction("MOVE_UP");
export var moveDown = createAction("MOVE_DOWN");
export var up = createAction("UP");
export var down = createAction("DOWN");
export var expand = createAction("EXPAND");
export var collapse = createAction("COLLAPSE");
// Reducer
export var reducer = createReducer(emptyState, (_a = {},
    // Load
    _a[load.type] = function (state, action) {
        if (action.payload.force) {
            state.expanded = emptyState.expanded;
        }
        state.nodeId = undefined;
        state.item = action.payload.data;
        if (state.expanded.length === 0) {
            // expand the first two levels to start with
            state.expanded = __spreadArrays([
                ROOT_ID
            ], state.item[ROOT_ID].children.map(function (childId) {
                return getNodeIdFromPath([ROOT_ID, childId]);
            }));
        }
    },
    // Set BaseURL
    _a[setBaseUrl.type] = function (state, action) {
        state.baseUrl = action.payload.baseUrl;
    },
    // Select Item
    _a[selectItem.type] = function (state, action) {
        state.nodeId = action.payload.nodeId;
    },
    // Edit Item
    _a[editItem.type] = function (state, action) {
        var _a = action.payload, id = _a.id, content = _a.content;
        // let's not worry about linking for now
        // if (Object.keys(state.item).includes(content)) {
        // 	// content is an id, replace it with a reference to the existing item.
        // 	const referencedItemId = content;
        // 	replaceAllReferencesToId(id, referencedItemId, state);
        // 	deleteItem(id, state);
        // }
        if (!state.nodeId) {
            return;
        }
        var node = new ItemNode({
            nodeId: state.nodeId,
            state: state,
        });
        node.item.content = content;
        potentiallyReselectNode(node.nodeId, state);
        // now handle the timeline
        // the data structure for the timeline is a flat list of dates of the topmost parent
        // the view aggregates those dates into chunks
        if (node.path.includes("timeline")) {
            // don't handle the timeline for timeline edits
            return;
        }
        var timelineNode = ItemNode.getByNodeId({
            nodeId: getNodeIdFromPath([ROOT_ID, "timeline"]),
            state: state,
        });
        if (!timelineNode) {
            return;
        }
        // update the timeline entry string if it exists
        var existingTimelineNode = findTodayTimelineEntryForItemId(id, state);
        if (existingTimelineNode) {
            existingTimelineNode.item.content = getTimelineString(node);
            return;
        }
        // if there's a timeline entry in another day then there's nothing more to do - don't capture edits
        var olderNodes = getOlderTimelineEntries(state);
        if (olderNodes.some(function (olderNode) { var _a; return ((_a = olderNode.childNodes[0]) === null || _a === void 0 ? void 0 : _a.item.id) === id; })) {
            return;
        }
        // if a parent has a timeline entry then there's nothing more to do
        var todaysNodes = getTodaysTimelineEntries(state);
        if (todaysNodes.some(function (todayNode) {
            return node.parentNode.path.includes(todayNode.childNodes[0].item.id);
        })) {
            return;
        }
        // add to timeline
        var timelineId = shortid.generate();
        var timelineDateId = shortid.generate();
        var timelineDateString = format(new Date(), DATE_FORMAT);
        state.item[timelineDateId] = {
            id: timelineDateId,
            content: timelineDateString,
            children: [],
        };
        state.item[timelineId] = {
            id: timelineId,
            content: getTimelineString(node),
            children: [node.item.id, node.parentNode.item.id, timelineDateId],
        };
        // add a timeline if the document doesn't have one already
        if (!state.item["timeline"]) {
            state.item["timeline"] = {
                id: "timeline",
                content: "Timeline",
                children: [],
            };
            state.item[ROOT_ID].children.push("timeline");
        }
        state.item["timeline"].children.unshift(timelineId);
        // @todo unlink any previous references and freeze their contents to x number of levels
        // const oldReferencedNodes = olderNodes.filter(olderNode =>
        // 	node.path.includes(olderNode.childNodes[0].item.id)
        // );
    },
    // Add Item
    _a[addItem.type] = function (state, action) {
        var _a = action.payload, id = _a.id, content = _a.content, children = _a.children;
        if (!state.nodeId) {
            return;
        }
        var node = new ItemNode({ nodeId: state.nodeId, state: state });
        node.insertItemAfterThisNode({ id: id, content: content, children: children });
    },
    // Remove Item
    _a[removeItem.type] = function (state) {
        if (!state.nodeId) {
            return;
        }
        var path = getPathFromNodeId(state.nodeId);
        var id = getItemInArrayByIndex(path, -1);
        var node = new ItemNode({ nodeId: state.nodeId, state: state });
        node.selectPreviousNode();
        node.delete();
    },
    // Indent Item
    _a[indentItem.type] = function (state, action) {
        var _a = getItemFromPath(state.item, getPathFromNodeId(state.nodeId)), id = _a.id, collection = _a.collection;
        var newParentId = getIndexFromItem(collection, id, -1);
        if (!newParentId) {
            return;
        }
        var newParent = state.item[newParentId];
        newParent.children.push(id);
        var path = getPathFromNodeId(state.nodeId);
        path.pop();
        path.push(newParentId, id);
        state.nodeId = getNodeIdFromPath(path);
        var parentPath = path.slice(0, -1);
        var pathId = getNodeIdFromPath(parentPath);
        var expandedSet = new Set(state.expanded);
        expandedSet.add(pathId);
        state.expanded = Array.from(expandedSet);
        removeItemFromArray(collection, id);
    },
    // Undent Item
    _a[undentItem.type] = function (state, action) {
        var _a = getItemFromPath(state.item, getPathFromNodeId(state.nodeId)), id = _a.id, parentId = _a.parentId, parentsParentId = _a.parentsParentId, collection = _a.collection;
        if (!parentId || !parentsParentId) {
            return;
        }
        var parentsCollection = getCollection(state.item, parentsParentId);
        insertItemInArrayAfter(parentsCollection, parentId, id);
        var path = getPathFromNodeId(state.nodeId);
        path.splice(-2);
        path.push(id);
        state.nodeId = getNodeIdFromPath(path);
        removeItemFromArray(collection, id);
    },
    // Move Up
    _a[moveUp.type] = function (state, action) {
        var _a = getItemFromPath(state.item, getPathFromNodeId(state.nodeId)), id = _a.id, index = _a.index, collection = _a.collection;
        var aboveItemId = getIndexFromItem(collection, id, -1);
        if (!aboveItemId) {
            return;
        }
        collection[index - 1] = id;
        collection[index] = aboveItemId;
    },
    // Move Down
    _a[moveDown.type] = function (state, action) {
        var _a = getItemFromPath(state.item, getPathFromNodeId(state.nodeId)), id = _a.id, index = _a.index, collection = _a.collection;
        var belowItemId = getIndexFromItem(collection, id, 1);
        if (!belowItemId) {
            return;
        }
        collection[index + 1] = id;
        collection[index] = belowItemId;
    },
    // Up
    _a[up.type] = function (state, action) {
        if (!state.nodeId) {
            return state;
        }
        var node = new ItemNode({ nodeId: state.nodeId, state: state });
        node.selectPreviousNode();
    },
    // Down
    _a[down.type] = function (state, action) {
        if (!state.nodeId) {
            return state;
        }
        var node = new ItemNode({ nodeId: state.nodeId, state: state });
        node.selectNextNode();
    },
    // Expand
    _a[expand.type] = function (state, action) {
        var pathId = getNodeIdFromPath(action.payload.path);
        var expandedSet = new Set(state.expanded);
        expandedSet.add(pathId);
        state.expanded = Array.from(expandedSet);
    },
    // Collapse
    _a[collapse.type] = function (state, action) {
        var pathId = getNodeIdFromPath(action.payload.path);
        var expandedSet = new Set(state.expanded);
        expandedSet.delete(pathId);
        state.expanded = Array.from(expandedSet);
    },
    _a));
function getItemFromPath(itemStore, path) {
    var id = getItemInArrayByIndex(path, -1);
    var parentId = getItemInArrayByIndex(path, -2);
    var parentsParentId = getItemInArrayByIndex(path, -3);
    var collection = parentId ? getCollection(itemStore, parentId) : [];
    var index = collection ? collection.indexOf(id) : -1;
    var children = itemStore[id].children;
    return { id: id, index: index, parentId: parentId, parentsParentId: parentsParentId, collection: collection, children: children };
}
function getCollection(itemStore, parentId) {
    return itemStore[parentId].children;
}
// previously used for linking
// const replaceAllReferencesToId = (
// 	oldId: string,
// 	newId: string,
// 	state: State
// ) => {
// 	getAllItemsWithIdInChildren(oldId, state, item => {
// 		item.children = item.children.map(childId =>
// 			childId === oldId ? newId : childId
// 		);
// 	});
// };
export function getNodeIdFromPath(path) {
    return path.join(",");
}
export function getPathFromNodeId(nodeId) {
    if (!nodeId) {
        return [];
    }
    return nodeId.split(",");
}
var ItemNode = /** @class */ (function () {
    function ItemNode(_a) {
        var nodeId = _a.nodeId, state = _a.state;
        this.nodeId = nodeId;
        this.state = state;
        this.path = getPathFromNodeId(nodeId);
        this.item = this.state.item[getItemInArrayByIndex(this.path, -1)];
    }
    ItemNode.getByNodeId = function (_a) {
        var nodeId = _a.nodeId, state = _a.state;
        var path = getPathFromNodeId(nodeId);
        var item = state.item[getItemInArrayByIndex(path, -1)];
        if (!item) {
            return null;
        }
        return new ItemNode({ nodeId: nodeId, state: state });
    };
    Object.defineProperty(ItemNode.prototype, "parentNode", {
        get: function () {
            return new ItemNode({
                nodeId: getNodeIdFromPath(this.path.slice(0, -1)),
                state: this.state,
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemNode.prototype, "childNodes", {
        get: function () {
            var _this = this;
            return this.item.children.map(function (childId) {
                return new ItemNode({
                    nodeId: getNodeIdFromPath(__spreadArrays(_this.path, [childId])),
                    state: _this.state,
                });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemNode.prototype, "expanded", {
        get: function () {
            return this.state.expanded.includes(this.nodeId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemNode.prototype, "index", {
        get: function () {
            return this.parentNode.item.children.indexOf(this.item.id);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemNode.prototype, "previousSibling", {
        get: function () {
            if (this.index === 0) {
                return null;
            }
            var siblingId = this.parentNode.childNodes[this.index - 1].item.id;
            var newPath = __spreadArrays(this.parentNode.path, [siblingId]);
            return new ItemNode({
                nodeId: getNodeIdFromPath(newPath),
                state: this.state,
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemNode.prototype, "nextSibling", {
        get: function () {
            if (this.index === this.parentNode.childNodes.length - 1) {
                return null;
            }
            var siblingId = this.parentNode.childNodes[this.index + 1].item.id;
            var newPath = __spreadArrays(this.parentNode.path, [siblingId]);
            return new ItemNode({
                nodeId: getNodeIdFromPath(newPath),
                state: this.state,
            });
        },
        enumerable: false,
        configurable: true
    });
    ItemNode.prototype.select = function () {
        this.state.nodeId = this.nodeId;
    };
    ItemNode.prototype.selectNextNode = function () {
        if (this.expanded) {
            this.childNodes[0].select();
        }
        else {
            var node = this;
            while (!node.nextSibling) {
                node = node.parentNode;
            }
            node.nextSibling.select();
        }
    };
    ItemNode.prototype.selectPreviousNode = function () {
        if (!this.previousSibling) {
            this.parentNode.select();
            return;
        }
        var node = this.previousSibling;
        while (node.expanded) {
            node = getItemInArrayByIndex(node.childNodes, -1);
        }
        node.select();
    };
    ItemNode.prototype.insertItemAfterThisNode = function (newItem) {
        this.state.item[newItem.id] = newItem;
        // if expanded then add as a new child otherwise add as a new sibling
        if (this.expanded) {
            this.item.children.unshift(newItem.id);
        }
        else {
            insertItemInArrayAfter(this.parentNode.item.children, this.item.id, newItem.id);
        }
        this.selectNextNode();
    };
    ItemNode.prototype.delete = function () {
        var _this = this;
        var id = this.item.id;
        var timelineEntry = findTodayTimelineEntryForItemId(id, this.state);
        if (timelineEntry) {
            timelineEntry.delete();
        }
        var allParents = Object.keys(this.state.item)
            .filter(function (itemId) {
            var item = _this.state.item[itemId];
            return item.children.includes(id);
        })
            .map(function (parentId) { return _this.state.item[parentId]; });
        allParents.forEach(function (parent) {
            parent.children = parent.children.filter(function (childId) { return childId !== id; });
        });
        delete this.state.item[id];
    };
    return ItemNode;
}());
export { ItemNode };
function getTimelineString(node) {
    return "added " + node.item.content + " to " + node.parentNode.item.content;
}
var locale = __assign(__assign({}, enGB), { formatDistance: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var arg1 = args[0], otherArgs = args.slice(1);
        var todayKeys = ["lessThanXMinutes", "xMinutes", "aboutXHours"];
        if (todayKeys.includes(arg1)) {
            return "Today";
        }
        // @ts-ignore
        return enGB.formatDistance.apply(enGB, args);
    } });
export function getGroupedTimeline(state) {
    var timelineNode = ItemNode.getByNodeId({
        nodeId: getNodeIdFromPath([ROOT_ID, "timeline"]),
        state: state,
    });
    if (!timelineNode) {
        return [];
    }
    var sortedTimelineNodes = timelineNode.childNodes
        .map(function (childNode) {
        var _a;
        var inputDateString = ((_a = childNode.childNodes[2]) === null || _a === void 0 ? void 0 : _a.item.content) ||
            childNode.item.content.split(" - ")[0]; // this is the briefly used, legacy date format
        var parsedDate = parse(inputDateString, DATE_FORMAT, new Date());
        var dateString = isValid(parsedDate) ? inputDateString : "Invalid Date";
        return {
            childNode: childNode,
            dateString: dateString,
        };
    })
        .sort(function (a, b) { return a.dateString.localeCompare(b.dateString); })
        .reverse();
    var groupedMap = sortedTimelineNodes.reduce(function (entryMap, timelineNode) {
        var parsedDate = parse(timelineNode.dateString, DATE_FORMAT, new Date());
        var dateGroupString = isValid(parsedDate)
            ? formatDistance(parsedDate, new Date(), {
                addSuffix: true,
                locale: locale,
            })
            : timelineNode.dateString;
        return entryMap.set(dateGroupString, __spreadArrays((entryMap.get(dateGroupString) || []), [
            timelineNode.childNode,
        ]));
    }, new Map());
    return Array.from(groupedMap).map(function (_a) {
        var dateGroupString = _a[0], timelineItemNodes = _a[1];
        return ({
            id: dateGroupString,
            content: dateGroupString,
            children: timelineItemNodes.map(function (timelineItemNode) { return timelineItemNode.item.id; }),
        });
    });
}
function getTodaysTimelineEntries(state) {
    var timelineNode = ItemNode.getByNodeId({
        nodeId: getNodeIdFromPath([ROOT_ID, "timeline"]),
        state: state,
    });
    if (!timelineNode) {
        return [];
    }
    return timelineNode.childNodes.filter(function (childNode) {
        var _a;
        var childNodesDate = parse((_a = childNode.childNodes[2]) === null || _a === void 0 ? void 0 : _a.item.content, DATE_FORMAT, new Date());
        return isToday(childNodesDate);
    });
}
function getOlderTimelineEntries(state) {
    var timelineNode = ItemNode.getByNodeId({
        nodeId: getNodeIdFromPath([ROOT_ID, "timeline"]),
        state: state,
    });
    if (!timelineNode) {
        return [];
    }
    return timelineNode.childNodes.filter(function (childNode) {
        var _a;
        var childNodesDate = parse((_a = childNode.childNodes[2]) === null || _a === void 0 ? void 0 : _a.item.content, DATE_FORMAT, new Date());
        return !isToday(childNodesDate);
    });
}
function findTodayTimelineEntryForItemId(itemId, state) {
    var todaysNodes = getTodaysTimelineEntries(state);
    return todaysNodes.find(function (todayNode) { var _a; return ((_a = todayNode.childNodes[0]) === null || _a === void 0 ? void 0 : _a.item.id) === itemId; });
}
function potentiallyReselectNode(inputNodeId, state) {
    var closestNodeId = findClosestNodeId(inputNodeId, state);
    if (inputNodeId !== closestNodeId) {
        state.nodeId = closestNodeId;
        var path = getPathFromNodeId(closestNodeId);
        var expandedSet = new Set(state.expanded);
        for (var i = 1; i <= path.length; i++) {
            expandedSet.add(getNodeIdFromPath(path.slice(0, i)));
        }
        state.expanded = Array.from(expandedSet);
    }
}
function findClosestNodeId(inputNodeId, state) {
    var inputPath = getPathFromNodeId(inputNodeId);
    var id = getItemInArrayByIndex(inputPath, -1);
    var inputNode = new ItemNode({ nodeId: inputNodeId, state: state });
    var node = inputNode;
    var endPath = [id]; // This isn't quite correct because we might be missing the item we're requesting but let's come back to this later
    while (node.parentNode.item) {
        node = node.parentNode;
        endPath.unshift(node.item.id);
    }
    var nodeWithoutParentItem = node;
    var pathBelowMissingParent = endPath;
    // let's look inside our grouped timeline
    var groupedTimeline = getGroupedTimeline(state);
    var timelineGroupWithMissingNode = groupedTimeline.find(function (timelineGroup) {
        return timelineGroup.children.includes(nodeWithoutParentItem.item.id);
    });
    if (timelineGroupWithMissingNode) {
        var path = __spreadArrays([
            ROOT_ID,
            "timeline",
            timelineGroupWithMissingNode.id
        ], pathBelowMissingParent);
        return getNodeIdFromPath(path);
    }
    else {
        // look through the rest of the document. We can do this later.
        return inputNodeId;
    }
}
