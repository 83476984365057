var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import { useSelector } from "react-redux";
import { getGroupedTimeline, getNodeIdFromPath, getPathFromNodeId, } from "./duck";
import { Node } from "./Node";
export var TimelineNode = function (_a) {
    var nodeId = _a.nodeId;
    var path = getPathFromNodeId(nodeId);
    var state = useSelector(function (state) { return state; });
    var groupedTimeline = getGroupedTimeline(state);
    return (React.createElement(Node, { nodeId: nodeId, content: "Timeline", children: groupedTimeline.map(function (timelineGroup, index) { return ({
            nodeId: getNodeIdFromPath(__spreadArrays(path, [timelineGroup.id])),
            content: timelineGroup.content,
            children: timelineGroup.children,
            readonly: false,
            expanded: index === 0,
        }); }), readonly: true, expanded: true }));
};
