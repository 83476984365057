import React from "react";
import { useSelector } from "react-redux";
import { getLastItemInArray } from "./array-util";
import { getPathFromNodeId } from "./duck";
import { Node } from "./Node";
export var ItemNode = function (_a) {
    var nodeId = _a.nodeId, readonly = _a.readonly;
    var path = getPathFromNodeId(nodeId);
    var itemId = getLastItemInArray(path);
    var item = useSelector(function (state) { return state.item[itemId]; });
    return (React.createElement(Node, { nodeId: nodeId, content: item.content, children: item.children, expanded: false, readonly: readonly }));
};
