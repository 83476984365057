/**
 * Get items from an array by it's index with negative indexes working from the end of the array.
 * @param input Input array.
 * @param index Index to get. Negative indexes work from the end of the array.
 */
export function getItemInArrayByIndex(input, index) {
    if (index > 0) {
        return input[index];
    }
    else {
        return input[input.length + index];
    }
}
export function getIndexFromItem(input, item, index) {
    var itemIndex = input.indexOf(item);
    return input[itemIndex + index];
}
export function insertItemInArrayAfter(input, afterItem, item) {
    var insertIndex = input.indexOf(afterItem) + 1;
    input.splice(insertIndex, 0, item);
}
export function getLastItemInArray(input) {
    return getItemInArrayByIndex(input, -1);
}
export function removeItemFromArray(input, item) {
    var targetIndex = input.indexOf(item);
    input.splice(targetIndex, 1);
}
export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(function (_a, index) {
        var key = _a[0], value = _a[1];
        return [
            key,
            fn({ key: key, value: value, index: index }),
        ];
    }));
}
