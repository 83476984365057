var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/** @jsx jsx */
import { jsx, Spinner } from "theme-ui";
import { toolbarButtonStyles } from "./ToolbarButton";
import { Fragment, useRef, useState, useEffect } from "react";
import { usePromise } from "@cadbox1/use-promise";
export var ToolbarUploadButton = function (_a) {
    var onUpload = _a.onUpload, onUploadComplete = _a.onUploadComplete, title = _a.title, children = _a.children;
    var fileInput = useRef(null);
    var _b = useState(), fileName = _b[0], setFileName = _b[1];
    var _c = useState(), base64File = _c[0], setBase64File = _c[1];
    var uploadRequest = usePromise({
        promiseFunction: function () { return __awaiter(void 0, void 0, void 0, function () {
            var uploadResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!base64File) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, onUpload({
                                name: fileName,
                                base64: base64File,
                            })];
                    case 1:
                        uploadResponse = _a.sent();
                        onUploadComplete(uploadResponse);
                        return [2 /*return*/, uploadResponse];
                }
            });
        }); },
    });
    useEffect(function () {
        uploadRequest.call();
    }, [base64File]);
    var handleChange = function () {
        var _a;
        var files = (_a = fileInput.current) === null || _a === void 0 ? void 0 : _a.files;
        if (!files || files.length < 1) {
            return;
        }
        var file = files[0];
        setFileName("");
        setBase64File("");
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            if (!reader.result || typeof reader.result !== "string") {
                return;
            }
            // https://stackoverflow.com/a/52311051/728602
            var encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
            if (encoded.length % 4 > 0) {
                encoded += "=".repeat(4 - (encoded.length % 4));
            }
            setFileName(file.name || new Date().toUTCString());
            setBase64File(encoded);
            if (fileInput.current) {
                fileInput.current.value = "";
            }
        });
        reader.readAsDataURL(file);
    };
    var handleClick = function () {
        var _a;
        (_a = fileInput.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (jsx(Fragment, null,
        jsx("input", { type: "file", onChange: handleChange, ref: fileInput, sx: { display: "none" } }),
        jsx("button", { onClick: handleClick, title: title, sx: toolbarButtonStyles }, uploadRequest.pending ? jsx(Spinner, { size: 13 }) : children)));
};
